
////////// SIDEBAR ////////
$sidebar-width-lg: 243px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px; 

$sidebar-dark-bg:linear-gradient(#2a1c5a, #4b3a6e);
$sidebar-dark-menu-color: #fff;
$sidebar-dark-submenu-color: #fff;
$sidebar-dark-menu-active-bg: #fff;
$sidebar-dark-menu-active-color: #000;
$sidebar-dark-menu-hover-bg: transparent;
$sidebar-dark-menu-hover-color: #fff;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 20%);
$sidebar-dark-menu-icon-color: #fff;
$sidebar-dark-menu-arrow-color: rgba(255,255,255,1);
$sidebar-dark-menu-border-color: #f2edf3;
$sidebar-dark-icon-bg: rgba(194,244,219,.12);
$sidebar-dark-icon-color: #fff;
$sidebar-dark-action-border: rgba(151,151,151,.27);
$sidebar-dark-sub-menu-active-color: #fff;


$sidebar-light-bg: #fff;
$sidebar-light-menu-color: #444343;
$sidebar-light-menu-active-bg: #fff;
$sidebar-light-menu-active-color: #444343;
$sidebar-light-menu-hover-bg: lighten($sidebar-light-bg, 10%);
$sidebar-light-menu-hover-color: #27367f;
$sidebar-light-submenu-color: $sidebar-light-menu-color;
$sidebar-light-submenu-hover-bg: initial;
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 30%);
$sidebar-light-icon-active-color: theme-color(success);
$sidebar-light-icon-bg: rgba(0,0,0,.12);

$sidebar-light-menu-icon-color: $white; 
$sidebar-light-menu-arrow-color: #9e9da0;

$sidebar-menu-font-size: 0.937rem;
$sidebar-menu-padding-y: .675rem;
$sidebar-menu-padding-x: .75rem;

$sidebar-submenu-font-size: .9375rem;
$sidebar-submenu-item-padding: .75rem 0 .75rem 0;
$sidebar-submenu-item-margin: 0 1.5rem 0 1.5rem;

$sidebar-icon-font-size: 1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$rtl-sidebar-submenu-padding: 0 1.5rem 0 0; 

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-dark: $sidebar-dark-menu-hover-bg;
$icon-only-menu-bg-light: $sidebar-light-menu-hover-bg;

///////// SIDEBAR ////////