.bullet-rounded {
  content: "";
  width: 6px;
  height: 6px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
}
.statitics-card {
  .statitics-card-content {
    i {
      font-size: 40px;
    }
  }
  .statitics-card-header {
    i {
      font-size: 18px;
      color: $text-muted
    }
  }
}
.product-card {
  overflow: hidden;
  .product-box {
    position: relative;
    padding: 0px 31px 25px 31px;
    display: flex;
    justify-content: center;
    .product-name-box {
      position: absolute;
      width: 83%;
      color: #ffffff;
      transform: translateY(-50%);
      @each $color, $value in $theme-colors {
        .product-name-box-#{$color} {
          height: 75px;
          box-shadow: 0 0 10px 5px rgba($value, 0.35);
          background: $value;
          transition: all 0.25s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
        } 
      }
      .add-product-button {
        position: absolute;
        right: -17px;
        top: 25%;
      }
    }
    .product-description-box {
      margin-top: 60px;
      .product-description-box-text {
        color: #3c4858;
      }
      .br-theme-fontawesome-stars,
      .br-theme-css-stars {
        .br-widget {
          a {
            font-size: 16px;
            margin-right: 0px;
            &:after{
              content: "\2606";
              color: theme-color(warning);
            }
            &.br-selected,
            &.br-active {
              &:after {
                content: "\2605";
                color:  theme-color(warning);
              }
            }
          }
        }
      }
      a {
        font-size: .875rem;
      }
    } 
  }
  img {
    transition: transform .2s; /* Animation */
  }
  &:hover {
    img {
      transform: scale(1.15);
    }
  }
}
.revenue-world-map {
  width: 100%;
  height: 200px;
}
.earning-flot-chart {
  height: 207px;
}
.dashboard-table {
  &.table {
    tbody,
    thead {
      tr {
        td,
        th {
          text-align: left;

          font-size:14px;
          &:first-child{
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        } 
      } 
    } 
  } 
  &.sales-table {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    &.table {
      thead,
      tbody {
        tr {
          th,
          td {
            color: inherit;
            padding: 10px 10px;
            font-weight: normal;
            font-size: 14px;
          }
          &:hover {
            background: #f8f6ff;
          }
        }
      }
    }
  }
}

.product-list {
  .product-list-header {
    padding: 0;
    margin-bottom: 10px;
  }
  .product-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding: 1rem 0;
    .options-icon {
      font-size: 16px;
      cursor: pointer;
    }
  }
}
.dashboard-inline-datepicker {
  &.mdc-card {
      background: #f9f9fa;
      position: relative;
      min-height: 400px;
  }
  .datepicker-box-top {
    display: flex;
    justify-content: space-between;
    background: theme-color(info);
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    .mdc-button {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }
}
.to-do-list {
  .to-do-list-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    i {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .mdc-tab-bar {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.17);
  }
  .mdc-tab-indicator {
    .mdc-tab-indicator__content--underline {
      border-color: #ffffff;
    }
  } 
  .to-do-list-content {
    padding: 10px 30px;
    background: #ffffff;
    .to-do-list-item {
      border: 1px solid #eeeeee;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      &:before {
        content: "";
        width: 4px;
        height: 60px;
        background: #ffcd00;
      }
    }
    .delete-icon {
      color: #bababa;
      font-size: 16px;
      cursor: pointer;
    }
  }
} 
