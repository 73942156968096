/* Cards */

.card {
  border: 0;
  border-radius: 0;
  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 1rem;
    }
  }
  h5 {
    &.card-title {
    font-size: 1rem !important;
    font-weight: bold;
    }

  }
  .card-title {
    color: $card-title-color;
    text-transform: capitalize;
    font-family: $type1-regular;
    font-size: 1rem !important;
    font-weight: 500;
    margin-bottom: 9px;
  }
  .card-subtitle {
    @extend .text-gray;
    font-family: $type1-regular;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  
  }
  .card-description {
    color: $card-description-color;
    margin-bottom: 1.5rem;
    font-family: $type1-regular;
  }
  &.card-outline-success {
		border: 1px solid theme-color("success");
	}
	&.card-outline-primary {
		border: 1px solid theme-color("primary");
	}
	&.card-outline-warning {
		border: 1px solid theme-color("warning");
	}
	&.card-outline-danger {
		border: 1px solid theme-color("danger");
	}
	&.card-rounded {
		@include border-radius(5px);
	}

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top:0;
      right: 0;
      height: 100%;
    }
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }

  &.card-no-shadow {
    box-shadow: none;
  }
}
.mdc-card {
  &.info-card {
    position: relative;
    padding: 17px 25px;
    .card-inner {
      margin-right: 25%;
      .card-title {
        
      }
      .card-icon-wrapper {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 57px;
        height: 57px;
        border-radius: 2px;
        background-color: theme-color(success);
        @include display-flex;
        @include align-items(center);
        @include justify-content(center); 
        box-shadow: 0 0 10px 5px rgba(theme-color(success), .35);
        @media(max-width: 992px) {
          right: 20px;
         }
        i {
          color: #fff;
          font-size: 30px;
        }
      }
    }
    @each $color, $value in $theme-colors {
      &.info-card--#{$color} {
        .card-inner {
          .card-icon-wrapper {
            background-color: $value;
            box-shadow: 0 0 10px 5px rgba($value, .35);
            &:hover {
              box-shadow: 0 0 10px 5px rgba($value, .15);
            }
          }
        }
      }
    }
  }
  .options-icon,
  .refresh-icon {
    font-size: 20px;
    color: #bbbbbb;
    cursor: pointer;
  }
  background: $card-bg;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  .card-title {
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: normal;
    @media(max-width: 768px) {
      font-size: 16px;
    }
  }
  .card-sub-title {
    font-size: 14px;
    font-weight: 300;
  }
}
@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}
