/* Full Calendar */

.fc {
  button {
    @extend .btn;
    @extend .btn-sm;
    height: auto;
    background-image: none;
    text-shadow: none;
    font-family: $type1-regular;
    background-color: $white;
    border-color: $border-color;
    text-transform: capitalize;
    color: $fullcalendar-color;
    padding: 0.5rem 0.25rem;
    .fc-icon-right-single-arrow,
    .fc-icon-left-single-arrow {
      &:after {
        color: $fullcalendar-color;
        font: normal normal normal 20px/1 "Material Design Icons";
      }
    }  
    .fc-icon-right-single-arrow {
      &:after {
        content: "\F142";
      }
    }
    .fc-icon-left-single-arrow {
      &:after {
        content: "\F141";
      }
    }
  }
  .fc-button-group {
    @extend .btn-group;
    .fc-button {
      background-color: $white;
      margin: 0;
      &.fc-state-active {
        color: theme-color(danger);        
      }
    }
  }
  .fc-header-toolbar {
    margin-top: 2rem;
    .fc-center {
      display: block;
      h2 {
        font-size: 1rem;  
        @media (max-width: 991px) { 
          font-size: 14px;
          margin-top: 1rem;
        }      
      }
    }
    .fc-left,
    .fc-right,
    .fc-center {
      @media (max-width: 991px) { 
        display: block;
      }
    }
  }
  .fc-widget-header {
    border: 0;
    table {
      tr {
        th {
          border-width: 0 0 1px 0;
          text-align: right;
          padding: 1rem;
          @media (max-width: 991px) { 
            padding: 1rem 0;
          }
          span {
            font-size: 10px;
            text-transform: uppercase;
            color: $fullcalendar-color;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  .fc-content-skeleton {
    table {
      tr {
        td {
          padding: .5rem;
          font-size: .875rem;
        }
      }
    }
  }
  .fc-event {
    border: 0;
    color: $white;
    padding: .5rem;
    &.fc-start {
      background: theme-color(primary); 
      border-radius: 4px;
      border-left: 4px solid theme-color(primary);        
    }
    &.fc-not-start,
    &.fc-not-end {
      background: theme-color(danger);
      border-left: 4px solid theme-color(danger);
      padding-left: .5rem;
    }
    &.fc-not-end {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .fc-title,
    .fc-time {
      color: $white;
    }
  }
}

.fc-external-events {
  .fc-event {
    background: $white;
    color: $black;
    border-width: 0 0 0 4px;
    padding: 16px;
    border-radius: 0;
    margin-bottom: 10px;
    &:nth-child(1) {
      border-color: theme-color(primary);
    }
    &:nth-child(2) {
      border-color: theme-color(danger);
    }
    &:nth-child(3) {
      border-color: theme-color(success);
    }
    &:nth-child(4) {
      border-color: theme-color(info);
    }
    &:nth-child(4) {
      border-color: theme-color(warning);
    }
  }
}