/* Fonts */

//PT_Sans
@font-face {
  font-family: 'Roboto-light'; 
  src: url('../../fonts/Roboto/Roboto-Light.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Roboto/Roboto-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Roboto/Roboto-Light.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/Roboto/Roboto-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto-regular';
  src: url('../../fonts/Roboto/Roboto-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Roboto/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Roboto/Roboto-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/Roboto/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto-medium';
  src: url('../../fonts/Roboto/Roboto-Medium.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Roboto/Roboto-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Roboto/Roboto-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/Roboto/Roboto-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto-bold';
  src: url('../../fonts/Roboto/Roboto-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Roboto/Roboto-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Roboto/Roboto-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/Roboto/Roboto-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}
