/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}
.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;

  .input-group-text {
    border-color: $border-color;
    padding: $input-padding-y .5rem $input-padding-y  1rem;
    color: $input-placeholder-color;
    .rtl & {
      padding: $input-padding-y 1rem $input-padding-y  .5rem;
    }
  }
}
.input-group-prepend {
  margin-right: .937rem;
}
.form-control {
  border: none;
  font-family: $type1-regular;
  font-size: $input-font-size;
  &.form-control-lg {
    padding: 0.94rem 0;
  }
  &.form-control-sm {
    padding: 0.5rem 0;
  }
}

select {
  &.form-control {
    padding: .625rem 0;
    border: 0;
    outline: none;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}
.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
    border-bottom: 1px solid $border-color;
    padding: .4375rem 0;
  }
}
.form-control-sm {
  height: 2.875rem;
}
.form-check {
  margin-top: 10px;
  margin-bottom: 5px;
}
.bmd-form-group{
  &.bmd-form-group-sm {
    padding: 0 !important;
  }
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
 font-size: $input-font-size;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: $input-font-size;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: $input-font-size;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: $input-font-size;
}
.bmd-form-group {
  input::placeholder {
    font-size: $input-font-size;
  }
}
.file-upload-browse {
  margin-bottom: 0;
}

.bmd-form-group {
  &.bmd-form-group-sm {
    .bmd-label-static {
      top: 0;
    }
  }
}