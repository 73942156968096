////////// COLOR SYSTEM //////////

$blue: #5E50F9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #E91E63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray : #2e383e; 
$dodger-blue : #3498db;


$colors: (blue: $blue,
indigo: $indigo,
purple: $purple,
pink: $pink,
red: $red,
orange: $orange,
yellow: $yellow,
green: $green,
teal: $teal,
cyan: $cyan,
white: $white,
gray: #434a54,
gray-light: #aab2bd,
gray-lighter: #e8eff4,
gray-lightest: #e6e9ed,
gray-dark: #0f1531,
black: #000000);


$theme-colors: (primary: #7a00ff,
secondary: #ff420f,
success: #00b67a,
info: #00bbdd,
warning: #ffc107,
danger: #fc5a5a,
light: #f0f1f1,
dark: #3e4b5b,
white:        #fff
);

$theme-gradient-colors: (primary: linear-gradient(to right, #da8cff, #9a55ff),
secondary: linear-gradient(to right, #e7ebf0, #868e96),
success: linear-gradient(to right, #84d9d2, #07cdae),
info: linear-gradient(to right, #90caf9, #047edf 99%),
warning: linear-gradient(to right, #f6e384, #ffd500),
danger: linear-gradient(to right, #ffbf96, #fe7096),
light: linear-gradient(to bottom, #f4f4f4, #e4e4e9),
dark: linear-gradient(89deg, #5e7188, #3e4b5b));

//$social-colors: (twitter: #4DBFF5, facebook: #648ACA, google: #E55F4B, linkedin: #0177b4, pinterest: #cc2127, youtube: #e52d27, github: #333333, behance: #1769ff, dribbble: #ea4c89, reddit: #ff4500);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #e9eaef;
$footer-bg: $content-bg;
$footer-color: theme-color(light);
$border-color: rgba(151,151,151, .3);
////////// COLOR VARIABLES //////////

////////// SOCIAL COLORS //////////

$social-colors: (twitter: #2caae1,
facebook: #3b579d,
google: #dc4a38,
linkedin: #0177b5,
pinterest: #cc2127,
youtube: #e52d27,
github: #333333,
behance: #1769ff,
dribbble: #ea4c89,
reddit: #ff4500);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1-light: 'Roboto-light',
sans-serif;
$type1-regular: 'Roboto-regular',
sans-serif;
$type1-medium: 'Roboto-medium',
sans-serif;
$type1-bold: 'Roboto-bold',
sans-serif;

$default-font-size: .75rem; // 14px as base font size

$text-muted: #bababa;
$body-color: #000;

////////// FONT VARIABLES //////////

///////// CARD ////////
$card-bg: #fff;
$card-description-color: #76838f;
$card-title-color: #000;
///////// CARD ////////

//////// TABLES ////////
$table-accent-bg: $content-bg;
$table-hover-bg: $content-bg;
$table-cell-padding: .9375rem;
$table-border-color: $border-color;
$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
//////// TABLES ////////

////////// MODALS VARIABLES //////////
$modal-content-bg: $content-bg;
////////// MODALS VARIABLES ////////// 

///////// NAVBAR ////////
$navbar-bg: #e9eaef;
$navbar-height: 64px;
$navbar-menu-color: #111111;
$navbar-font-size: .875rem;
$navbar-icon-font-size: 1.25rem;
$nvabar-brand-bg: #2a1c5a; 
$nvabar-toggler-color: #000;
$nvabar-search-icon-color: #8e94a9;
$navbar-search-bg: #fff;
$nav-dropdown-color: #111111;
$navbar-placeholder-color : #a9a7a7;

///////// NAVBAR ////////



///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: .775rem;
$btn-padding-x: .75rem;
$btn-line-height: 1;

$btn-padding-y-xs: .5rem;
$btn-padding-x-xs: .75rem;

$btn-padding-y-sm: .50rem;
$btn-padding-x-sm: .81rem;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: .875rem;
$btn-font-size-xs: .625rem;
$btn-font-size-sm: .875rem;
$btn-font-size-lg: .875rem;

$btn-border-radius: .1875rem;
$btn-border-radius-xs: .1875rem;
$btn-border-radius-sm: .1875rem;
$btn-border-radius-lg: .1875rem;

///////// BUTTONS ////////



////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: .75rem;
$tooltip-padding-y: .4rem;
$tooltip-padding-x: .75rem;
$tooltip-border-radius: .375rem;

////////// TOOLTIP VARIABLES //////////



/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #181824;
$input-font-size: .8125rem;

$input-padding-y: 0.94rem;
$input-padding-x: 0;
$input-line-height: 1;

$input-padding-y-xs: .5rem;
$input-padding-x-xs: .75rem;

$input-padding-y-sm: .50rem;
$input-padding-x-sm: .81rem;

$input-padding-y-lg: .94rem;
$input-padding-x-lg: 1.94rem;

$input-height:          2.875rem;
$input-height-sm:       2.575rem;
$input-height-lg:       3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
$dropdown-menu-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
////////  DROPDOWNS ///////


////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$boxed-container-width: 1200px;
$border-property: 1px solid $border-color;
$card-spacing-y: 30px;
$card-padding-y: 25px;
$card-padding-x: 25px;
$card-border-radius: .3125rem;
$grid-gutter-width: 25px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////



////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: .5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;
////////// BREAD CRUMBS VARIABLES //////////


////////// MODALS VARIABLES //////////
$modal-inner-padding: 15px;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, .5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, .5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform .4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #e3e5ef;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
////////   TABS VARIABLES /////////


///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////


////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

////////   MISC  //////////////
$page-title-color: #111;
////////   MISC  //////////////

$card-mixed-danger :linear-gradient(to bottom, #d41459 , #911a6c);

////////   CHAT  //////////////
$chat-container-height:950px;
////////   CHAT  //////////////

$bullet-line-list-shape-bg: color(white) !default;
$mdc-drawer-link-color: #fff;

$mdc-drawer-list-item-spacing: 0 1.5rem;
$mdc-drawer-list-item-margin: $mdc-drawer-list-item-spacing;