/* Layouts */

.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}


// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-mini});
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .nav {
        .nav-item {
          padding: 10px 0;
          margin: 0;
          .nav-link {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column-reverse);
            text-align: center;
            position: relative;
            border-bottom: none;
            .menu-title {
              display: block;
              margin: auto;
            }
            .menu-sub-title{
              margin: auto;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            .icon-bg {
              margin-top: 8px;
              i {
                &.menu-icon {
                  display:block;
                  margin-right: auto;
                  margin-left: auto;
                  margin-top: 5px;
                }
                
              }
            }
            i {

            &.menu-arrow {
              display: inline-block;
              margin-left: 5px;
              position: absolute;
              top: 50%;
              right: 10px;
              @include transform(translateY(-50%));
              &:before {
                content: "\F140";
              }
            }

            }
            
            &[aria-expanded="true"]{
              .menu-arrow{
                &:before{
                  content: "\f143";
                }
              }
            }
          }
          &.nav-category {
            text-align:center;
          }
          &.sidebar-user-actions {
            .sidebar-profile-text {
              display: none;
            } 
            &.hover-open {
              .nav-link {
                .menu-title {
                  left: 45px;
                }
              }
            }

          }
        }
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-mini});
    }
    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .sidebar {
        .nav {
          &:not(.sub-menu) {
            > .nav-item {
              border-bottom: 1px solid rgba($sidebar-light-menu-color, .2);
              .nav-link {
                height: auto;
                padding: $sidebar-mini-menu-padding;
                i {
                  &.menu-icon {
                    margin-bottom: .5rem;
                  }
                }
              }
            }
          }
          &.sub-menu {
            padding: 0;
            border-top: none;
            .nav-item {
              padding: 5px 0;
              .nav-link {
                padding: 7px 0 7px 25px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                text-align: left;
                width: 66%;
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0;
          .nav-link {
            display: block;
            text-align: center;
            padding: 0.675rem 0rem;
            .menu-title,
            .badge,.menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px); 
              }
            }
            .icon-bg{
              margin-right: auto;
              margin-left: auto;
              margin-bottom: 10px;
              i{
                margin-left: auto;
                margin-right: auto;
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title{
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          .profile-actions {
            display: none;
          }
          .premium-card {
            display: none;
          }
          &.user-info {
            display: none;
          }
          .collapse {
            display: none;
          }
          &.hover-open {
              .nav-link {
                .menu-title {
                  @include display-flex;
                  @include align-items(center);
                  background: $icon-only-menu-bg-dark;
                  @at-root #{selector-append(".sidebar-light", &)} {
                      background: $sidebar-light-menu-active-bg;
                  }
                  padding: 0.5rem 1.4rem;
                  left: $sidebar-width-icon;
                  position: absolute;
                  text-align: left;
                  top: 0;
                  bottom: 0;
                  width: $icon-only-collapse-width;
                  z-index: 1;
                  line-height: 1.8;
                  @at-root #{selector-append(".rtl", &)} {
                    left: auto;
                    right: $sidebar-width-icon;
                    text-align: left;
                  }
                  @at-root #{selector-append(".sidebar-light", &)} {
                    color: $dark;
                  }
                  &:after {
                    display: none;
                  }
                }
              }
              .collapse,
              .collapsing {
                display: block;
                padding: .5rem 0;
                background: $icon-only-menu-bg-dark;
                @include border-radius(0 0 5px 0);
                @at-root #{selector-append(".sidebar-light", &)} {
                    background: $sidebar-light-bg;
                }
                position: absolute;
                left: $sidebar-width-icon;
                width: $icon-only-collapse-width;
                @at-root #{selector-append(".rtl", &)} {
                    left: auto;
                    right:$sidebar-width-icon;
                    @include border-radius(0 0 0 5px);
                }
              }
            }
            &.documentation-link {
              margin: 0 !important;

              .nav-link {
                background: transparent;
                color: $white;
                @include border-radius(0px);

                .icon-bg {
                  @include display-flex;
                }

                .menu-title {
                  margin: auto;
                  background: $sidebar-light-bg ;
                  color: $sidebar-light-menu-color;
                  font-weight:400;
                }
              }
            }
            &.sidebar-user-actions {
              .user-details {
                display: none;
              }
              .sidebar-user-menu {
                padding: 0 1.5rem;
              }
            }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-right: 0;
              margin-left: auto;
              .nav-link {
                text-align: left;
                &:before{
                  left: unset;
                  right: 0;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      width: 0;
    }

    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;

      .sidebar {
        transition: none;
      }
    }

    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
        height: 100%;
        -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Firefox 3.5 - 3.6 */
        box-shadow: 0 0 3px 1px #a7a3a3;
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}


//Fixed sidebar
.sidebar-fixed {
  @media(min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;

      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;

        &.sub-menu {
          max-height: none;
        }
      }
    }

    .main-panel {
      margin-left: $sidebar-width-lg;
    }

    &.sidebar-icon-only {
      .main-panel {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: #e5e7f1;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }
    .navbar {
      &.fixed-top {
        margin: auto;
        max-width: $boxed-container-width;
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
    }
  }
  &.sidebar-icon-only {
    .sidebar {
      .nav {
        &.sub-menu {
          padding: 0 1.5rem 0 1.5rem;
        }
      }
    }
  }

  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}
