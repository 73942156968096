.nav-tabs {
	.nav-link {
		background: $nav-tabs-link-bg;
		color: $nav-tabs-link-color;
		border-radius: 0;
		border: 1px solid $border-color;
		padding: .75rem 1.5rem;

		@media (max-width:768px) {
			padding: .75rem;
		}
		
	}
	.nav-item {
		&:first-child {
			.nav-link {
				border-radius: 4px 0 0 0;
			}	
		}
		&:last-child {
			.nav-link {
				border-radius: 0 4px 0 0;
			}
		}
	}
	&.nav-tabs-vertical {
		@extend .flex-column;
		border-bottom: 0;
		.nav-link {
			&.active {
				border-bottom-color: $border-color;
			}
		}
		.nav-item {
			&:first-child {
				.nav-link {
					border-radius: 4px 4px 0 0;
				}	
			}
			&:last-child {
				.nav-link {
					border-radius: 0 0 4px 4px;
				}
			}
		}
	}
	&.nav-tabs-vertical-custom {
		@extend .nav-tabs-vertical;
		background: theme-color(primary);
		border-radius: 4px;
		.nav-link {
			background: transparent;
			border-color: rgba($white, .2);
			color: darken(theme-color(primary), 30%);
			font-family: $type1-bold;
			padding: 1.75rem;

			@media (max-width:768px) {
				padding: 1.75rem .5rem;
			}
			&.active {
				color: $white;
				border-color: rgba($white, .2);
			}
		}
	}
	&.tab-transparent {
		.nav-item {
			.nav-link {
				background: transparent;
				color: theme-color(light);
				border-radius: 0;
				border: none;
				padding: .875rem 1.25rem;
				@media (max-width: 991px) {
					padding: .875rem .5rem;
				}
				position: relative;
				&.active {
					color: theme-color(dark);
					font-weight: bold;
					&:after {
						content: '';
						width: 100%;
						height:2px;
						background: theme-color(success);
						position: absolute;
						left: 0;
						bottom: 0;
					}
				}
				
			}
			&:first-child {
				.nav-link {
					&:first-child {
						padding-left: 0;
						.rtl & {
							padding-right: 0;
							padding-left: 1.25rem;
						}
					}
				}
			}
		}
		

	}
}

.nav-pills {
	border-bottom: 1px solid $border-color;
	padding-bottom: 1rem;
	.nav-link {
		border: 1px solid $border-color;
		padding: .5rem 2rem;

		@media (max-width: 768px) {
			padding: .5rem;
		}
	}
	.nav-item {
		margin-right: 1rem;
	}
	&.nav-pills-vertical {
		@extend .flex-column;
		border-bottom: 0;
		.nav-item {
			margin-right: 0;
			margin-bottom: 1rem;
		}
	}
	&.nav-pills-custom {
		border-bottom: 0;
		.nav-link {
			border-radius: 20px;
			padding: .5rem 2.5rem;
			background: $nav-pills-custom-bg;
			color: $black;
			&.active {
				background: theme-color(danger);
				color: $white;
			}
		}
	}
}



.tab-content {
	border: 1px solid $border-color;
	border-top: 0;
	padding: 2rem 1rem;
	text-align: justify;
	&.tab-content-vertical {
		border-top: 1px solid $border-color;
	}
	&.tab-content-vertical-custom {
		border: 0;
		padding-top: 0;
	}
	&.tab-content-custom-pill {
		border: 0;
		padding-left: 0;
	}
	&.tab-transparent-content {
		border: none;
		padding: 1.5rem 0 0 0;
	}
}
.rtl {
	.nav-tabs {
		&.tab-transparent {
			padding-right: 0;
		}
	}

}
.mdc-tab-wrapper {
	.nav {
		&.nav-tabs {
			background: theme-color(success);
			.nav-item {
				justify-content: space-between;
				padding: 0 0;
				.nav-link {
					color: $white;
					background-color: transparent;
					border-bottom: 1px solid $white;
					border: none;
					text-transform: uppercase;
					&.active {
						border-bottom: 3px solid $white;
					}
				}
			}
		}
	}
}
 